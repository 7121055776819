.b-paginacao{
	background-color: #ffffff;

	&:hover {
		background-color: darken(#ffffff, 20);
	}
}
.b-paginacao-active {
	color: $colorPrimary;
	font-weight: 600;

	&:hover {
		@extend .b-paginacao-active;
	}
}

.dialog-paginacao {
	display: block;
	width: 100%;
	max-width: 480px;

	.container_botoes{
		display: block;
		width: 100%;
		overflow: auto;
		max-height: 15rem;
		padding-bottom: 5rem;
		@include border-box;
	}
}