.formsimple-input-bc {
	width: 100%;
	background-color: white;
	border: solid 1px $gray4;
	border-radius: .2rem 0 0 .2rem;
	color: $gray1;
	display: inline-block;
	padding: 0 1rem;
}

.width_input_home{
	width: 15% !important;
}

@media all and (max-width: 1023px){

	.width_input_home{
		width: 50% !important;
	}
}