$botaoWidth: 10rem;

.container_paginacao{
	max-width: 40rem;
	vertical-align: middle;
	display: inline-block;

	& .paginacao_anterior {
		display: inline-block;
		vertical-align: top;
	}

	& .paginacao_paginas {
		display: inline-block;
		vertical-align: top;

		& button {
			border-top: 1px solid $gray4;
			border-bottom: 1px solid $gray4;
			border-left: 1px solid $gray4;
			border-right: 0 solid $gray4;

			&:first-child{
				border-left: 0 solid $gray4;
			}
			&:last-child{
				//border-right: 1px solid $gray4;
			}
		}
	}

	& .paginacao_seguinte {
		display: inline-block;
		vertical-align: top;
	}
}