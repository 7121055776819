.busca-filtros{
	display: block;
	width: 100%;
	background-color: white;
	padding: 1rem;
	border: 1px solid $gray1;
	@include border-box;

	& h2 {
		text-align: center;
		border-bottom: 1px solid $gray1;
	}
}

.pd_btns_busca_mobile{
	padding: 0 .1rem;
}

.btn_mostrar_mais_busca_interna{
	text-align: center;
	width: 10%;
	background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	color: white;
	font-size: 1rem;
	font-weight: 600;
	border-radius: 2rem;
	border: solid 2px white;
	padding: .5rem .8rem;
	box-shadow: 5px 7px 15px rgba(black, .3);
	cursor: pointer;
	transition: all .25s ease-out;
	&:hover{
		background: linear-gradient(to bottom right, $colorSecondary, $colorPrimary);
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .45rem 0;
	line-height: 1.5rem;
	border: none;
	border-radius: .2rem;
	margin-top: 2px;
	margin-right: 3px;
	background-color: $colorPrimary;
	color: white;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}
.botao_limpar_int{
	text-align: center;
	padding: .40rem 0;
	line-height: 1.5rem;
	border: solid 1px $gray4;
	border-radius: .2rem;
	margin-top: 2px;
	margin-right: 3px;
	background-color: white;
	color: $colorTerciary;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	&:hover{
		background-color: darken(white, 5);
	}
}


// SETA DA PAGINAÇAO

@-webkit-keyframes uparrow {
	0% { -webkit-transform: translateY(0); opacity: 0.4 }
	100% { -webkit-transform: translateY(-0.4em); opacity: 0.9 }
  }
@-webkit-keyframes downarrow {
0% { -webkit-transform: translateY(0); opacity: 0.4 }
100% { -webkit-transform: translateY(0.4em); opacity: 0.9 }
}

.arrow {
	border-color:transparent;
	border-style:solid;
	border-width:0 2em;
	display:block;
	height:0;
	margin-top: 2rem;
	opacity:0.4;
	text-indent:-9999px;
	transform-origin: 50% 50%;
	width:0;
}
.up {
	-webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
	border-bottom:2em solid $colorPrimary;
}
.down {
	-webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
	border-top:2em solid $colorPrimary;
}


.height-but{
	height: 3rem !important;
}

.bg-person{
	background-color: $colorPrimary;
}
@media all and (max-width: 1023px){
	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		padding-bottom: 7rem;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.busca-filtrosssss{
		overflow: auto;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 10;
		background: #ffffff;
		left: 0;
		z-index: 99999;

		& .busca-filtros {
			padding-top: 4rem;
			padding-bottom: 7rem;
			border: 0 solid $gray1;
		}
		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
}
.heigt{
	height: 2.3rem !important;
}
.busca_container-fixed{
	display: block;
	position: fixed;
	z-index: 10;
}
