.simuladores{
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    &:hover {
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        filter: grayscale(0%);
    }
    & img{
        &:hover{
            transform: scale(1.1);
            transition: .15s all;
            @include border-box;    
        } 
    }
}

.btn_financ{
    width: 25rem;
    display: block;
    margin: auto;
    text-align: center;
    & p{
        background-color: #003563;
        color: white;
        font-size: 1.1rem;
        font-weight: 600;
        text-align: center;
        padding: .8rem 1rem;
        border-radius: .2rem;
    }
}

.img_financ{
    margin: auto;
    width: 55rem;
    & img{
        width: 100%;
    }
}