/* NAV MOBILE */
.headermobile {
	display: block;
	width: 100%;
	padding: 0.5rem 0;
	@include border-box;
	background-color: $colorPrimary;

	& .left {

		width: 30%;
		vertical-align: middle;
		display: inline-block;
		@include border-box;

		& .logo {
			display: block;
			height: 3rem;

			& a {
				height: inherit;
				width: 100%;
				display: block;

				& img {
					display: block;
					width: auto;
					height: inherit;
				}
			}
		}
	}

	& .center {
		display: inline-block;
		width: 35%;
		vertical-align: middle;
	}

	& .right {
		display: inline-block;
		width: 30%;
		vertical-align: middle;
		text-align: right;
	}

	& .b {
		color: #ffffff !important;
	}
}