.header1{
	background-color: rgba(black, .5);
	width: 100%;
	display: block;
	z-index: 99999999;
	position: absolute;
	top: 0;
}

.header2{
	background-color: $colorTerciary;
	width: 100%;
	display: block;
	z-index: 99999999;
}
	
.menuacao_btn{
	background-color: $colorPrimary !important;
	color: transparent !important;
}

.headerindex {
	height: 100vh;
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	position: relative;	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(black, .4);
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;//era block
			height: calc(100vh + 1px);
			min-height: 40rem;
			width: 100%;
			z-index: 2;
			background-image: url('/jnh/header/bg_header1.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center top;
		}
	}

	& .busca {
		width: 50%;
		text-align: center;
		position: absolute;
		left: 50%;
		top: 35%;
		transform: translate(-50%, 35%);

		& .btns_tipo_busca{
			display: block;
			width: 100%;
			& h1{
				font-size: 2.5rem;
				color: white;
				font-weight: 600;
				text-shadow: 2px 2px 5px black;
			}
			& .botoes{
				display: block;
				width: 50%;
				& button{
					background-color: white;
					border: solid 1px $gray5;
					border-radius: 2rem;
					font-size: 1.6rem;
					color: $colorTerciary;
					font-weight: 600;
					padding: .5rem 1.5rem;
					margin: 0;
					cursor: pointer;
					transition: all .15s;
				}
			}
		}

		& .busca-filtro{
			width: 100%;
			display: block;
			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
				& .btn-home{
					padding: .62rem 1rem;
					border-radius: 0 .2rem .2rem 0;
					width: 25%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorPrimary;
					color: white;
					font-size: 1rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					&:hover{
						background-color: darken($colorPrimary, 5);
					}
					
				}
			}
		}
	}

	& .frase {
		position: absolute;
		width: 50%;
		right: 5%;
		top: 32%;
		text-align: left;

		& span{
			line-height: 3rem !important;
			font-size: 2.3rem;
		}
		& h2 {
			color: #ffffff;
			font-size: 2rem;
		}
	}

}
.headerinterno {
	height: 5rem;
	z-index: 1;
}
.busca-top{
	width: 100%;
	background-color: $colorPrimary;
	height: 2rem;
}


#sub_menu_lancamentos, #sub_menu_vendas{
	display: none;
	width: 200px;
	max-width: 200px;
	position: absolute;
	left: 0;
	top: 2.4rem;
	background-color: $colorPrimary;
	box-shadow: 7px 7px 10px rgba(black, .2);
	border-radius: .2rem;
	z-index: 3;
	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 25rem;
		& p{
			font-size: 1.1rem !important;
			font-weight: 400;
			background-color: transparent;
			padding: 1.2rem 1.5rem;
			margin: 0;
			text-align: left;
			transition: all .15s;
			border-left: solid 3px transparent;
			&:hover{
				border-left: solid 3px white;
				padding-left: 2rem;
				background-color: darken($colorPrimary, 5);
			}
		}
		& .titletipo{
			color: white;
			font-weight: 600;
			line-height: 1.3em;
		}

		& .itemtipo{
			color: white !important;
			line-height: 1.3em;
			&:hover{
				font-weight: 600;
			}
		}
	}
}

.tarja_top{
	width: 100%;
	display: block;
	height: 1.8rem;
	& .creci_header{
		color: $gray2;
		font-size: 1rem;
		font-weight: 400;
		width: 50%;
		display: inline-block;
		vertical-align: middle;
		text-align: left;
	}
	& .itens_tarja{
		width: 50%;
		display: inline-block;
		& ul {
			flex: 0 0 100%;
			display: flex;
			align-items: center;
			height: 1.5rem;
			margin: 0%;
			padding: 0%;
			list-style: none;
			justify-content: flex-end;
			transition: all .25s ease;
			will-change: height;
			
			& .ith{
				display: inline-block;
				padding: 0 20px;

				& a{
					justify-content: space-between;
					color: $gray3;
					font-weight: 400;
					font-size: .9rem;
					&:hover{
						color: $colorPrimary;
					}
				}
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	background-color: transparent;
	& .superior {
		display: block;
		width: 100%;
		color: #ffffff;
		z-index: 3;
		padding: .5rem 0;
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: 11rem;
			padding: .5rem 0;
			& img {
				display: block;
				width: 100%;
			}
		}

		/* GRUPO HEADER */

		$largura_share: 7;
		$largura_menu: 3;
		$largura_creci: 10;
		$largura_contact: 13;
		$diferenca: 11;
		& .share {
			display: inline-block;
			vertical-align: middle;
			width: #{$largura_share}rem;
			text-align: center;
			& .item{
				display: inline-block;
				vertical-align: middle;
				padding: 0.5rem;
			}
		}

		& .creci {
			text-align: center;
			display: inline-block;
			width: #{$largura_creci}rem;
			vertical-align: middle;
			color: $gray1;
			font-size: 0.9rem;
		}
		& .menu {
			text-align: right;
			display: inline-block;
			width: #{$largura_menu}rem;
			vertical-align: middle;
		}

		& .sessions {
			display: inline-block;
			width: calc(100% - #{($largura_menu  + $diferenca)}rem);
			vertical-align: middle;

			& .itens_header{
				display: inline-block;
				width: 20%;
				& p{
					text-align: center;
					color: white;
					font-weight: 600;
					font-size: .9rem;
					user-select: none;
				}
			}

			& .itens_header2{
				width: 50%;
				display: inline-block;
				vertical-align: middle;

				& .itemsession{
					position: relative;
					display: inline-block;
					vertical-align: middle;
					width: 33.3%;

					& .title_is{
						width: 50%;
						display: inline-block;
						vertical-align: middle;

						& .itemsession_title{
							display: block;
							width: 100%;
							font-size: 1rem;
							color:  white;
							text-align: center;
							font-weight: 600;
							padding: 0;
							@include border-box;
							& i{
								font-size: .85rem;
								color:  white;
								font-weight: 400;
							}
							&:hover{
								font-weight: 600;
							}
						}
					}

					&:hover{
						#sub_menu_vendas, #sub_menu_lancamentos {
							display: initial;
						}
					}
					
				}

				& .central{
					width: 33.3%;
					display: inline-block;
					vertical-align: middle;
					& a{
						& p{
							background-color: $colorPrimary;
							border-radius: .2rem;
							display: block;
							width: 100%;
							font-size: 1rem;
							color:  white;
							text-align: center;
							font-weight: 600;
							padding: .3rem .5rem;
							@include border-box;
						}
					}
				}
			}


			& .itens_header3{
				display: inline-block;
				width: 25%;
				& a{
					display: inline-block;
					vertical-align: middle;
					padding: 0 .5rem;
					& p{
						text-align: center;
						vertical-align: middle;
						color: white;
						font-weight: 400;
						font-size: .9rem;
						& i{
							vertical-align: middle;
							font-size: 1.5rem;
							font-weight: 400;
							color: white;
						}
					}


					& .p_wpp{
						text-align: center;
						vertical-align: middle;
						color: white;
						font-weight: 400;
						font-size: 1rem;
						padding: 0 1rem;
						& i{
							vertical-align: middle;
							font-size: 1.5rem;
							font-weight: 400;
							color: white;
						}
						&:hover{
							font-weight: 600;
						}
					}
				}
			}
			
		}

		& .contact{
			display: inline-block;
			width: #{$largura_contact}rem;
			vertical-align: middle;
			text-align: center;
			& .mg-tx{
				margin-bottom: 1rem !important;
				margin-top: 0.1rem !important;
			}
			& .mg-tx2{
				margin-bottom: 0 !important;
				margin-top: 0 !important;
			}
		}

	}
}

.header-frases-arte{
	font-family: 'Quick Signature';
	color: lighten(white, 10%);
	text-shadow: 7px 2px 4px rgba(0, 0, 0, 1);
	font-size: 10rem;
	font-weight: 300;
	line-height: 30px;
}

.header-mob{
	width: 100%;
	display: block;
	& .log{
		display: inline-block;
		vertical-align: middle;
		width: 10rem;
		& img{
			width: 100%;
		}
	}
	& .btn-men{
		display: inline-block;
		vertical-align: middle;
		margin-right: 0;
		text-align: right !important;
		width: calc(100% - 10rem);
	}
}

.bg-headerinterno {
	background-color: darken($colorPrimary, 5);
}

.headermobile-btn{
	position: absolute;
	width: 100%;
	max-width: 280px;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -60%);
}
.container-bg-headermobile{
	display: block;
	position: absolute;
	top: 0;
	overflow: hidden;
	z-index: -1;
	height: 100%;
	width: 100%;
}
.headermobile {
	display: block;
	width: 100%;
	height: calc(100vh - 65px);
	min-height: calc(100vh - 65px);
	position: relative;
	background-color: $colorPrimary !important;
	will-change: background-image;
	background-image: url('/jnh/header/bg-header-01-mobile.jpg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center top;
	animation-name: headermobile;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 30s;
	animation-direction: normal;
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (min-width: 1201px) and (max-width: 1366px){
	.headerpc{
	
		& .superior{
			$largura_menu: 5;
			$diferenca: 11;
			& .sessions {
				display: inline-block;
				width: calc(100% - #{($largura_menu  + $diferenca)}rem);
				vertical-align: middle;
	
				& .itens_header{
					display: inline-block;
					width: 15%;
					& p{
						text-align: center;
						color: white;
						font-weight: 400;
						font-size: .8rem;
						user-select: none;
					}
				}
	
				& .itens_header2{
					width: 55%;
					display: inline-block;
					vertical-align: middle;
	
				}
				& .itens_header3{
					display: inline-block;
					width: 28%;
					& a{
						display: inline-block;
						vertical-align: middle;
						padding: 0 .5rem;
						& p{
							text-align: center;
							vertical-align: middle;
							color: white;
							font-weight: 400;
							font-size: .9rem;
							& i{
								vertical-align: middle;
								font-size: 1.5rem;
								font-weight: 400;
								color: white;
							}
						}
	
						& .p_wpp{
							text-align: center;
							vertical-align: middle;
							color: white;
							font-weight: 400;
							font-size: .9rem;
							padding: 0 1rem;
							& i{
								vertical-align: middle;
								font-size: 1.5rem;
								font-weight: 400;
								color: white;
							}
							&:hover{
								font-weight: 600;
							}
						}
					}
				}
				
			}
		}
	}

	.headerindex{
		height: 100vh;
		display: block;
		width: 100%;
		padding: 0;
		@include border-box;
		z-index: 2;
		position: relative;	
	}
}


@media all and (min-width: 1024px) and (max-width: 1200px){
	.headerpc{
	
		& .superior{
			$largura_menu: 6;
			$diferenca: 11;
			& .sessions {
				display: inline-block;
				width: calc(100% - #{($largura_menu  + $diferenca)}rem);
				vertical-align: middle;
	
				& .itens_header{
					display: inline-block;
					width: 12%;
					& p{
						text-align: center;
						color: white;
						font-weight: 600;
						font-size: .8rem;
						user-select: none;
					}
				}
	
				& .itens_header2{
					width: 58%;
					display: inline-block;
					vertical-align: middle;
				}

				& .itens_header3{
					display: inline-block;
					width: 30%;
					& a{
						display: inline-block;
						vertical-align: middle;
						padding: 0 .5rem;
						& p{
							text-align: center;
							vertical-align: middle;
							color: white;
							font-weight: 400;
							font-size: .9rem;
							& i{
								vertical-align: middle;
								font-size: 1.5rem;
								font-weight: 400;
								color: white;
							}
						}
	
						& .p_wpp{
							text-align: center;
							vertical-align: middle;
							color: white;
							font-weight: 400;
							font-size: .9rem;
							padding: 0 1rem;
							& i{
								vertical-align: middle;
								font-size: 1.5rem;
								font-weight: 400;
								color: white;
							}
							&:hover{
								font-weight: 600;
							}
						}
					}
				}
				
			}
		}
	}

	.headerindex{
		height: 100vh;
		display: block;
		width: 100%;
		padding: 0;
		@include border-box;
		z-index: 2;
		position: relative;	
	}
}

@media all and (max-width: 1023px){
	
	.header1{
		background-color: rgba(black, .5);
		width: 100%;
		display: block;
		z-index: 99999999;
		position: absolute;
		top: 0;
	}

	.header2{
		background-color: $colorTerciary;
		width: 100%;
		display: block;
		z-index: 99999999;
	}

	.headerindex{
		height: 100vh;
		display: block;
		width: 100%;
		padding: 0;
		@include border-box;
		z-index: 2;
		position: relative;	

		& .container-bg-headerpc{
			display: block;
			position: absolute;
			top:0;
			overflow: hidden;
			z-index: -1;
			height: 100%;
			width: 100%;
	
			& .overlay_bg_home{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(black, .5);
				z-index: 2;
			}
	
			& .bg-headerpc{
				display: block;
				height: calc(100vh + 1px);
				min-height: 40rem;
				width: 100%;
				z-index: 2;
				background-image: url('/jnh/header/bg_header1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
			}

		}

		& .busca {
			width: 80%;
			text-align: center;
			position: absolute;
			left: 50%;
			top: 25%;
			transform: translate(-50%, 25%);
	
			& .btns_tipo_busca{
				display: block;
				width: 100%;
				& h1{
					font-size: 2rem;
					color: white;
					font-weight: 600;
				}
			}
	
			& .busca-filtro{
				width: 100%;
				display: block;
				& .bloco_busca{
					padding: 0;
					margin: 0;
					text-align: center;
					display: block;
					width: 100%;
					& .btn-home{
						padding: .8rem 1rem;
						border-radius: 0 .2rem .2rem 0;
						width: 50%;
						display: inline-block;
						vertical-align: middle;
						background-color: $colorPrimary;
						color: white;
						font-size: 1rem;
						font-weight: 600;
						border: none;
						cursor: pointer;
						&:hover{
							background-color: darken($colorPrimary, 5);
						}
						
					}
				}
			}
		}
	}
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}
	
}
