/* JS DATA COMPONENT */
.boss-js-date{
	transform: translateX(0px);
	transition: transform 0.15s ease;
	font-family: inherit;
	position: relative;
	z-index: 1;
	margin: 2px 0px 2px 0px;
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	outline: none;
	height: #{$inputHeight}px;
	line-height: #{$inputHeight - ($inputBorder * 2)}px;
	min-width: 85px;
	background-color: $inputBackgroundColor;
	border: #{$inputBorder}px solid $inputBorderColor;
	border-radius: #{$inputBorderRadius}px;
	outline: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-js-date select{
	font-size: $inputTextSize;
	cursor: pointer;
	color: $inputTextColor;
	margin: auto;
	padding: 0px #{$inputPdLeft}px 0px #{$inputPdRight}px;
	height: #{$inputHeight - ($inputBorder * 2)}px;
	z-index: 2;
	font-weight: 400;
	font-family: inherit;
	background-color: $gray4;
	border-radius: 0;
	border: none;
	outline: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-js-date-label{
	height: inherit;
	line-height: inherit;
	padding: 0px 10px;
	color: $inputTextColor;
	font-size: $inputTextSize;
	height: inherit;
	position: relative;
	z-index: 3;
	text-align: center;
	outline: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
}
.boss-js-date-show{
	left: -1px;
	right: -1px;
	margin-top: -4px;
	display: block;
	background-color: #FFF;
	border-left: 1px solid #AAA;
	border-right: 1px solid #AAA;
	border-bottom: 2px solid #AAA;
	border-color: $inputBorderColor !important;
	position: absolute;
	z-index: 4;
	padding: 5px 10px;
	outline: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-js-date-show table{
	width: 100%;
	margin-top: 10px;
}

.boss-js-date-show table *{
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
}
.boss-js-date-show thead{

}
.boss-js-date-show th, .boss-js-date-show td{
	font-size: $inputTextSize;
	text-align: center;
	margin: 0 !important;
	padding: 2px 0px;
	line-height: 22px !important;
	height: 22px !important;
	color: $inputTextColor;
	border: 1px solid $inputBorderColor;
	width: 14.285%;
}
.boss-js-date-show th, .boss-js-date-show td{
	cursor: default !important
}
.boss-js-date-domingo{
	background-color: $gray4;
	border-color: $gray4;
}
.boss-js-date-domingo-td{
	background-color: $gray4;
	border-color: $gray4;
}
.boss-js-date-show td[data-ativo="ativo"]:hover{
	background-color: $gray3;
	border-color: $gray3;
	color: #FFF;
	cursor: pointer !important;
}
.boss-js-date-show-ative{
	background-color: $inputBorderColorFocus !important;
	border-color: $inputBorderColorFocus !important;
	color: #FFF !important;
	font-weight: bold;
	cursor: pointer !important;
}
.boss-js-date-show-arrows{
	position: relative;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	height: #{$btnHeight}px;
	line-height: #{$btnHeight - ($btnBorder * 2) - 4}px;
	border: none;
	border-radius: $btnBorderRadius;
	padding-left: 0px;
	padding-right: 0px;
	width: #{$btnHeight}px;
	margin: 2px 0px 2px 0px;
	background-color: $gray4;
	font-size: 130%;
	font-weight: $btnFontWeight;
	color: $inputTextColor;
	font-family: inherit;
	text-transform: uppercase;
	letter-spacing:0px;
	word-spacing: normal;
	transition: background .07s linear;
	outline: none;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-js-date-show-arrows:hover, .boss-js-date-show-arrows:active, .boss-js-date-show-arrows:focus{
	background-color: $colorPrimary;
	color: $colorWhite;
}
.boss-js-date-show-button{
	position: relative;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	height: #{$btnHeight}px;
	line-height: #{$btnHeight - ($btnBorder * 2) - 4}px;
	border: none;
	border-radius: $btnBorderRadius;
	padding-left: $btnPdLeft;
	padding-right: $btnPdRight;
	margin: 2px 0px 2px 0px;
	background-color: $gray4;
	font-size: $btnFont;
	font-weight: $btnFontWeight;
	color: $inputTextColor;
	font-family: inherit;
	text-transform: uppercase;
	letter-spacing:0px;
	word-spacing: normal;
	transition: background .07s linear;
	outline: none;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-js-date-d{
	height: 0px !important;
	width: 0px !important;
	padding: 0px !important;
	margin: 0px !important;
	line-height: 0px !important;
	position: absolute;
	z-index: 0;
}
.boss-js-date-m{
	height: #{$btnHeight}px;
	width: calc((100% - #{$btnHeight * 2}px) / 2);
	line-height: #{$btnHeight - ($btnBorder * 2) - 4}px;
}
.boss-js-date-y{
	height: #{$btnHeight}px;
	width: calc((100% - #{$btnHeight * 2}px) / 2);
	line-height: #{$btnHeight - ($btnBorder * 2) - 4}px;
}

.boss-js-date label::after{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
	z-index: 0;
	text-align: center;
	width: #{$inputHeight}px;
	line-height: #{$inputHeight - ($inputBorder * 2)}px;
	height: #{$inputHeight - ($inputBorder * 2)}px;
	content: "\e313";
	font-size: 24px !important;
	color: rgba(0,0,0,0.5);
	font-family: 'icomoon' !important;
	transform: rotate(0deg);
	transform-origin: center;
	transition: transform 0.15s ease;
}
.boss-js-date-rotate::after{
	transform: rotate(180deg) !important;
}