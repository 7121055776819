#boss-confirm{
	position: fixed;
	display: block;
	z-index: 999;
	height: 101vh;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: -1px;
	background-color: rgba(0,0,0,0.9);
}
.boss-confirm{
	display: block;
	background-color: $colorWhite;
	margin: auto;
	width: 300px;
	margin-top: 40vh;
	box-shadow: 0px 2px 8px 4px rgba(0,0,0,0.3);
	border-radius: 4px;
	padding: 10px 20px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-confirm p{
	text-transform: uppercase;
}