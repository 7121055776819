/* SELECT */
.boss-select{
	transform: translateX(0px);
	transition: transform 0.15s ease;
	@include form-font();
	position: relative;
	margin: 2px 0px 2px 0px;
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	height: #{$inputHeight}px;
	min-width: #{$inputHeight}px;
	background-color: $inputBackgroundColor;
	border: #{$inputBorder}px solid $inputBorderColor;
	border-radius: #{$inputBorderRadius}px;
	@include select-border;
	@include border-box;
}
.boss-select select{
	position: absolute;
	font-size: $inputTextSize;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	cursor: pointer;
	color: $inputTextColor;
	margin: 0px;
	padding: 0px #{$inputPdLeft}px 0px #{$inputPdRight}px;
	height: #{$inputHeight - ($inputBorder * 2)}px;
	width: 100%;
	z-index: 2;
	@include form-font();
	background-color: rgba(0,0,0,0);
	border-radius: 0 #{$inputBorderRadius}px #{$inputBorderRadius}px 0;
	border: none;
	outline: none;
	@include border-box;
}
.boss-select label{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
	content: "";
	width: #{$inputHeight}px;
	z-index: 1;
}
.boss-select label::before{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
	content: "";
	width: #{$inputHeight}px;
	z-index: 0;
	text-align: center;
	border-radius: 0 #{$inputBorderRadius}px #{$inputBorderRadius}px 0;
	background-color: $labelBackgroundColor;
	line-height: #{$inputHeight - ($inputBorder * 2)}px;
	height: #{$inputHeight - ($inputBorder * 2)}px;
}
.boss-select label::after{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
	z-index: 0;
	text-align: center;
	width: #{$inputHeight}px;
	line-height: #{$inputHeight - ($inputBorder * 2)}px;
	height: #{$inputHeight - ($inputBorder * 2)}px;
	content: "\f078";
	font-size: 100% !important;
	color: $labelColor;
	font-family: 'FontIcons Pro' !important;
	transform: rotate(0deg);
	transform-origin: center;
	transition: transform 0.15s ease;
}
.boss-select-disabled{
	cursor: default !important;
	border-color: $gray4 !important;
	color: $gray4 !important;
}
.boss-select-disabled select{
	cursor: default !important;
	color: $gray4 !important;
}
.boss-select-disabled label::before{
	cursor: default !important;
	background-color: $gray4 !important;
}
.boss-select-disabled label::after{
	cursor: default !important;
	color: #FFF !important;
}


.boss-js-select {
	position: relative;
	display: inline-block;
	vertical-align: top;
	outline: none;
	height: 38px;
	line-height: 36px;
	min-width: 135px;
	background-color: rgba(0,0,0,0);
	border-radius: 5px;
	@include border-box;
}

.boss-js-select select {
	opacity: 0;
	position: absolute;
	z-index: 1;
	width: 1px;
	height: 1px;
	@include border-box;
}

.boss-js-select-label {
	cursor: pointer;
	height: inherit;
	line-height: inherit;
	padding: 0 5px;
	color: #3c3641;
	font-size: .9em;
	height: inherit;
	line-height: inherit;
	position: relative;
	z-index: 3;
	@include border-box;
}

.boss-js-select-label-show {
	left: -1px;
	right: -1px;
	display: block;
	background-color: #FFF;
	border: 1px solid #757575;
	position: absolute;
	z-index: 4;
	@include border-box;
}

.boss-js-select-search,
.boss-js-select-check-all {
	padding: 2px 5px;
	background-color: #FFF;
	height: inherit;
	line-height: inherit;
	@include border-box;
}

.boss-js-select-search-input {
	border: 1px solid #AAA;
	outline: none;
	height: 30px;
	width: 100%;
	padding-left: 5px;
	padding-right: 30px;
	@include border-box;
}

.boss-js-select-search div {
	@include user-select(none);
}

.boss-js-select-search span {
	cursor: pointer;
	top: 4px;
	right: 6px;
	width: 30px;
	height: 28px;
	display: block;
	text-align: center;
	position: absolute;
	color: #333;
	z-index: 2;
	color: #333;
	@include rotate(45deg);
	@include user-select(none);
	font: 500 20px/28px 'sans'
}

.boss-js-select div.button {
	cursor: pointer;
	float: right;
	vertical-align: middle;
	border: 1px solid #757575;
	background-color: #FFF;
	height: 26px;
	line-height: 24px;
	padding: 0 5px;
	margin-top: 3px;
	margin-left: 5px;
	min-width: 40px;
	font-size: 10px;
	font-weight: 600;
	border-radius: 5px;
	text-align: center;
	text-transform: uppercase;
	@include border-box;
}

.boss-js-select div.button:hover {
	background-color: #757575;
	color: #3c3641
}

.boss-js-select-list {
	background-color: #fff;
	width: 100%;
	display: block;
	max-height: 320px;
	overflow: auto
}

.boss-js-select-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%
}

.boss-js-select-list ul li {
	position: relative;
	list-style: none;
	padding: 2px 5px;
	@include border-box;
}