$formsimple_inputHeight: 38;
$formsimple_inputHeightMin: 28;
$formsimple_inputBorder: 1;
$formsimple_inputFontSize: .8;
$formsimple_inputBorderCheckbox: 1;
$formsimple_inputBorderColor: white;
$formsimple_inputBackgroundColor: rgba(0,0,0,0);
$formsimple_inputBorderRadius_a: 3;
$formsimple_inputBorderRadius_b: 3;
$formsimple_inputBorderRadius_c: 3;
$formsimple_inputBorderRadius_d: 3;
$formsimple_inputBorderRadiusCheckbox: 3;
$formsimple_inputPdLeft: 20;
$formsimple_inputPdRight: 20;
$formsimple_labelColor: $gray1;

@mixin formsimple-font(){
	font-family: inherit  !important;
	font-size: #{$formsimple_inputFontSize}rem !important;
	font-weight: 400 !important;
	color: $gray2 !important;
}

.formulario-titulo {
	@include formsimple-font();
	font-size: 16px !important;
}

@mixin prefix($propriedade, $valor){
	-webkit-#{$propriedade}: $valor !important;
	-moz-#{$propriedade}: $valor !important;
	-ms-#{$propriedade}: $valor !important;
	-o-#{$propriedade}: $valor !important;
	#{$propriedade}: $valor !important;
}

@import 'inputs.scss';
@import 'upload.scss';
@import 'textarea.scss';
@import 'select.scss';
@import 'radio.scss';
@import 'checkbox.scss';
@import 'selectmultiple.scss';