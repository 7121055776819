/* MINIATURA 1 */
.miniatura-imv-mobiliado{
	top: 72px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorTerciary;
	padding: 5px 10px;
	color: #ffffff;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.imoveldestaque{
	top: 46px;
	left: 0px;
	display: block;
	position: absolute;
	background-color: $colorQuarterly;
	padding: 5px 10px;
	color: $gray1;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 600;
	border-color:rgba(#ffffff, .5);
	font-size: 14px;
	@include border-box;
}

.cr {
	width: 150px;
	padding: 9px;
	position: absolute;
	text-align: center;
	color: #f0f0f0;
  }
  
  .cr-sticky {
	position: absolute;
  }
  
  /* Positions */
  
  .cr-top    { top: 25px; }
  .cr-bottom { bottom: 25px; }
  .cr-left   { left: -50px; }
  .cr-right  { right: -35px; }
  
  /* Rotations */
  
  .cr-top.cr-left,
  .cr-bottom.cr-right {
	transform: rotate(-45deg);
  }
  
  .cr-top.cr-right,
  .cr-bottom.cr-left {
	transform: rotate(45deg);
  }
  
  /* Colors */
  
  
  .cr-primary    { background-color: $colorPrimary; font-weight: 600; color:white;}

.linkg{
	&:hover{
		color: $colorPrimary !important;
	}
}
.pd-05{
	padding-top: 0.3rem;
}

.miniatura-imovel {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	position: relative;

	& .miniatura-imovel-img{
		border-radius: .2rem .2rem 0 0;
		display: block;
		width: 100%;
		background-color: $gray5;
		user-select: none;
		overflow: hidden;
		min-height: 30vh;

		& img{
			display: block;
			width: 100%;
			border-radius: .2rem .2rem 0 0;
		}

		& .miniatura_status{
			position: absolute;
			top: 2rem;
			left: .5rem;
			background-color: rgba($colorPrimary, .9);
			font-weight: 600;
			color: white;
			font-size: .75rem;
			line-height: inherit;
			padding: .1rem .8rem;
			border-radius: 0 .2rem .2rem 0;
			margin: 0;
		}
	}

	& .miniatura-imovel-detalhe{
		display: block;
		border: solid 1px lighten($gray4, 10);
		box-shadow: 0 5px 10px 0 rgba(black, .2);
		border-radius: 0 0 .2rem .2rem;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		position: relative;

		& .miniatura-titulo{
			display: block;
			width: 100%;

			& p{
				text-transform: initial;
				font-size: .9rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
			}
		}

		& .acao_tipo_cod{
			display: block;
			width: 100%;

			& .aca_tipo{
				width: 70%;
				display: inline-block;
				vertical-align: middle;
				font-size: .8rem;
				font-weight: 400;
				color: $gray2;
			}

			& .cod{
				width: 30%;
				display: inline-block;
				vertical-align: middle;
				font-size: .8rem;
				font-weight: 400;
				color: $gray2;
				text-align: right;
			}
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			height: 3.5rem;

			& ul{
				& li{
					color: $gray2;
					font-size: .8rem;
					font-weight: 400;
					& i{
						color: $colorPrimary;
						font-size: 1rem;
						font-weight: 500;
					}
				}
			}
		}

		& .miniatura-preco{
			display: inline-block;
			vertical-align: middle;
			width: 60% !important;
			& p{
				width: 100%;
				padding: .5rem 0;
				line-height: inherit;
				text-align: center;
				font-size: 1rem;
				font-weight: 600;
				color: white;
				background-color: $colorPrimary;
				border-radius: .2rem;
				transition: all .2s ease-in-out;
				margin: 0;
			}

			&:hover{
				& p{
					background-color: darken($colorPrimary, 5);
				}
			}
		}

		& .miniatura-btn-vermais{
			display: inline-block;
			vertical-align: middle;
			width: 40% !important;
			margin-right: 0;

			& p{
				font-size: .9rem;
				font-weight: 400;
				color: $gray2;
				text-align: right;
				transition: all .2s;
				margin: 0;

				& i{
					font-size: .8rem;
					font-weight: 600;
					color: transparent;
					transition: all .2s ease-in-out;
				}

				&:hover{
					color: $colorTerciary;
					& i{
						color: $colorPrimary;
					}
				}
			}
		}
	}
}

/* SÓ SE APLICA NO PC */
@media all and (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}
}

@media all and (max-width: 1023px){

	
}

