/*
	PAGINA INTERNA DO IMÓVEL
	-SESSÕES-	
*/
@import 'imoveis-ver/h1.scss';
@import 'imoveis-ver/acao-tipo-titulo.scss';
@import 'imoveis-ver/galeria.scss';
@import 'imoveis-ver/informacoes.scss';
@import 'imoveis-ver/promocao.scss';
@import 'imoveis-ver/mcmv.scss';
@import 'imoveis-ver/troca.scss';
@import 'imoveis-ver/infraestruturas.scss';
@import 'imoveis-ver/ckeditor.scss';
@import 'imoveis-ver/mapa.scss';
@import 'imoveis-ver/contato.scss';
@import 'imoveis-ver/favorito.scss';
@import 'imoveis-ver/nota.scss';
@import 'imoveis-ver/sharefavorinota.scss';

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin imovel--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin imovel--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin imovel--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin imovel--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin imovel--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin imovel--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin imovel--smart-old(){
}