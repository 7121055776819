/* LARGE SCREEN */
@media all and (min-width: 1201px) and (max-width: 1370px){
	@include imovel--large();
	@include bloco--large();
	@include header--large();
	@include content--large();
	@include site--large();
	@include forms--large();
	@include footer--large();
}
/* MEDIUM SCREEN */
@media all and (min-width: 1024px) and (max-width: 1200px){
	@include imovel--medium();
	@include bloco--medium();
	@include header--medium();
	@include content--medium();
	@include site--medium();
	@include forms--medium();
	@include footer--medium();
}
/* TABLET LANDSCAPE */
@media all and (min-width: 768px) and (max-width: 1023px){
	@include imovel--tablet-landscape();
	@include bloco--tablet-landscape();
	@include header--tablet-landscape();
	@include content--tablet-landscape();
	@include site--tablet-landscape();
	@include forms--tablet-landscape();
	@include footer--tablet-landscape();
}
/* TABLET PORTRAIT */
@media all and (min-width: 641px) and (max-width: 767px){
	@include imovel--tablet-portrait();
	@include bloco--tablet-portrait();
	@include header--tablet-portrait();
	@include content--tablet-portrait();
	@include site--tablet-portrait();
	@include forms--tablet-portrait();
	@include footer--tablet-landscape();
}

/* SMARTPHONE LANDSCAPE */
@media all and (min-width: 482px) and (max-width: 640px){
	@include imovel--smart-landscape();
	@include bloco--smart-landscape();
	@include header--smart-landscape();
	@include content--smart-landscape();
	@include site--smart-landscape();
	@include forms--smart-landscape();
	@include footer--smart-landscape();
}

/* SMARTPHONE PORTRAIT */
@media all and (min-width: 321px) and (max-width: 481px){
	@include imovel--smart-portrait();
	@include bloco--smart-portrait();
	@include header--smart-portrait();
	@include content--smart-portrait();
	@include site--smart-portrait();
	@include forms--smart-portrait();
	@include footer--smart-portrait();
}
/* OLD SMARTPHONE */
@media all and (max-width: 320px){
	@include imovel--smart-old();
	@include bloco--smart-old();
	@include header--smart-old();
	@include content--smart-old();
	@include site--smart-old();
	@include forms--smart-old();
	@include footer--smart-old();
}