/*
	spanduk = Banner em Javanês
 */
/* UX BANNER */
.spanduk{
	display: block;
	position: relative;
	z-index: 0;
	width: 100%;
	padding: 0px;
	background-color: #EEE;
	outline: none;
	overflow: hidden;
	min-height: 80px;
    height: 80px;

	& .spanduk-canvas{
		position: relative;
		display: block;
		text-align: center;
		padding: 0px;
		margin: 0px;
		width: inherit;
		height: inherit;
		max-width: inherit;
		max-height: inherit;
		overflow: hidden;
	}
	& .spanduk-canvas-hidden div{
		display: none !important;
	}
	& .spanduk-canvas div{
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		width: inherit;
		height: inherit;
		max-width: inherit;
		max-height: inherit;
		padding: 0px;
		margin: 0px;
		z-index: 4;
	}
	& .spanduk-canvas a{
		display: block;
		width: 100%;
		height: 100%;
		max-width: inherit;
		max-height: inherit;
		padding: 0px;
		margin: 0px;
	}
	& .spanduk-canvas img{
		width: auto !important;
		height: 100%;
		max-width: inherit;
		max-height: inherit;
		padding: 0px;
		margin: 0px;
		box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.1);
	}
	& .spanduk-nav-div{
		display: block;
		margin: auto;
		width: 100%;
		max-width: 1280px;
	}
	& .spanduk-left, .spanduk-right{
		cursor: pointer;
		position: absolute;
		z-index: 5;
		border: none;
		outline: none;
		border-radius: 18px;
		line-height: 36px;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		padding-left: 20px;
		padding-right: 20px;
		text-align: center;
		font-size: 14px;
		color: $colorWhite;
		background-color: rgba(0,0,0,0.2);

		&:hover, &:active, &:focus{
			color: $colorPrimary;
			background-color: $colorWhite;
		}
	}
	& .spanduk-left{
		left: 20px;
		bottom: 10px;
	}
	& .spanduk-right{
		right: 20px;
		bottom: 10px;
	}
	& .spanduk-legenda{
		position: absolute;
		z-index: 4;
		width: calc(100vw - 290px);
		left: 50%;
		transform: translateX(-50%);
		bottom: 10px;
		text-align: center;
		color: white;
		background-color: rgba(0,0,0,.9);
		font-weight: 600;
		font-size: 1rem;
		line-height: 36px;
		text-shadow: 0px 0px 1px #FFF;
    	border-radius: 18px;
	}
	& .spanduk-left:hover,
	& .spanduk-right:hover,
	& .spanduk-slide:hover,
	& .spanduk-slide-pause:hover{
		opacity: 1;
	}
	& .spanduk-slide{
		display: inline-block;
		cursor: pointer;
		width: 20px;
		height: 20px;
		margin: 10px;
		background-image: url(/jnh/icones/spanduk.png);
		background-repeat: no-repeat;
		background-size: 40px 120px;
	    background-position: 0px 0px;
		opacity: 0.3;
	}
	& .spanduk-slide-pause{
		display: inline-block;
		cursor: pointer;
		width: 20px;
		height: 20px;
		margin: 10px;
		background-image: url(/jnh/icones/spanduk.png);
		background-repeat: no-repeat;
		background-size: 40px 120px;
	    background-position: -20px 0px;
		opacity: 1;
	}
}



/* ANIMAÇÕES */
.vir-da-direita {
	animation: alternate virdadireita 0.5s ease-in-out;
	animation-fill-mode: forwards;
}
@keyframes virdadireita {
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}
.sair-direita {
	animation: alternate sairdireita 1s ease-in;
	animation-fill-mode: forwards;
}
@keyframes sairdireita {
	40% {
		opacity: 0.2;
	}
	100% {
		transform: translateX(100%);
		opacity: 0.2;
	}
}

.vir-da-esquerda {
	animation: alternate virdaesquerda 0.5s ease-in-out;
	animation-fill-mode: forwards;
}
@keyframes virdaesquerda {
	100% {
		transform: translateX(0%);
		opacity: 1;
	}
}
.sair-esquerda {
	animation: alternate sairesquerda 1s ease-in;
	animation-fill-mode: forwards;
}
@keyframes sairesquerda {
	40% {
		opacity: 0.2;
	}
	100% {
		transform: translateX(-100%);
		opacity: 0.2;
	}
}