@font-face {
    font-family: 'Lato';
    src: url('font/Lato-BoldItalic.woff2') format('woff2'),
        url('font/Lato-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('font/Lato-HairlineItalic.woff2') format('woff2'),
        url('font/Lato-HairlineItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('font/Lato-Black.woff2') format('woff2'),
        url('font/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('font/Lato-Bold.woff2') format('woff2'),
        url('font/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('font/Lato-BlackItalic.woff2') format('woff2'),
        url('font/Lato-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('font/Lato-Hairline.woff2') format('woff2'),
        url('font/Lato-Hairline.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('font/Lato-Light.woff2') format('woff2'),
        url('font/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('font/Lato-Italic.woff2') format('woff2'),
        url('font/Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('font/Lato-LightItalic.woff2') format('woff2'),
        url('font/Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('font/Lato-Regular.woff2') format('woff2'),
        url('font/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rosmatika';
    src: url('font/Rosmatika-Regular.woff2') format('woff2'),
        url('font/Rosmatika-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/////////////////////////////////////////////////////////////////////////
@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Italic.woff2') format('woff2'),
        url('font/OpenSans-Italic.woff') format('woff'),
        url('font/OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Regular.woff2') format('woff2'),
        url('font/OpenSans-Regular.woff') format('woff'),
        url('font/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Bold.woff2') format('woff2'),
        url('font/OpenSans-Bold.woff') format('woff'),
        url('font/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-ExtraBold.woff2') format('woff2'),
        url('font/OpenSans-ExtraBold.woff') format('woff'),
        url('font/OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-BoldItalic.woff2') format('woff2'),
        url('font/OpenSans-BoldItalic.woff') format('woff'),
        url('font/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('font/OpenSans-ExtraBoldItalic.woff') format('woff'),
        url('font/OpenSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Italic_1.woff2') format('woff2'),
        url('font/OpenSans-Italic_1.woff') format('woff'),
        url('font/OpenSans-Italic_1.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Light.woff2') format('woff2'),
        url('font/OpenSans-Light.woff') format('woff'),
        url('font/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-LightItalic.woff2') format('woff2'),
        url('font/OpenSans-LightItalic.woff') format('woff'),
        url('font/OpenSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Medium.woff2') format('woff2'),
        url('font/OpenSans-Medium.woff') format('woff'),
        url('font/OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-MediumItalic.woff2') format('woff2'),
        url('font/OpenSans-MediumItalic.woff') format('woff'),
        url('font/OpenSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Regular_1.woff2') format('woff2'),
        url('font/OpenSans-Regular_1.woff') format('woff'),
        url('font/OpenSans-Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('font/OpenSans-SemiBoldItalic.woff') format('woff'),
        url('font/OpenSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-SemiBold.woff2') format('woff2'),
        url('font/OpenSans-SemiBold.woff') format('woff'),
        url('font/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
